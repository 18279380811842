export const auth0Config = {
  client_id: String(process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID),
  domain: String(process.env.NEXT_PUBLIC_AUTH0_DOMAIN),
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
};

export const gtmConfig = {
  containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
};
