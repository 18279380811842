import { Photo } from '../types/photo';

const searchValue = [
  `https://prod-smart-onderhoudsconfigurator-assets.s3.eu-central-1.amazonaws.com/`,
  `https://test-smart-onderhoudsconfigurator-assets.s3.eu-central-1.amazonaws.com/`,
  `https://demo-smart-onderhoudsconfigurator-assets.s3.eu-central-1.amazonaws.com/`,
];
const newBaseProdUrl = `https://onderhoudsconfigurator-prod-assets.keepgettingbetter.io/`;
const newBaseTestUrl = `https://onderhoudsconfigurator-test-assets.keepgettingbetter.io/`;
const newBaseDemoUrl = `https://onderhoudsconfigurator-demo-assets.keepgettingbetter.io/`;

export const replaceBaseUrls = (originalImages: Photo[]): Photo[] => {
  if (originalImages.length > 0) {
    return originalImages.map(image => {
      if (image.url.includes(searchValue[0])) {
        return {
          ...image,
          url: image.url.replace(searchValue[0], newBaseProdUrl),
        };
      }

      if (image.url.includes(searchValue[1])) {
        return {
          ...image,
          url: image.url.replace(searchValue[1], newBaseTestUrl),
        };
      }

      if (image.url.includes(searchValue[2])) {
        return {
          ...image,
          url: image.url.replace(searchValue[2], newBaseDemoUrl),
        };
      }

      return image;
    });
  }

  return [];
};

export const replaceBaseUrl = (originalString?: string): string => {
  if (!originalString || originalString === '') {
    return '';
  }

  if (originalString.includes(searchValue[0])) {
    return originalString.replace(searchValue[0], newBaseProdUrl);
  }

  if (originalString.includes(searchValue[1])) {
    return originalString.replace(searchValue[1], newBaseTestUrl);
  }

  return originalString;
};
