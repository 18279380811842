import React, { useEffect } from 'react';
import type { FC } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import nProgress from 'nprogress';
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/cache';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SettingsProvider } from '../contexts/settings-context';
import { AuthProvider } from '../contexts/auth0-context';
import { gtmConfig } from '../config';
import { gtm } from '../lib/gtm';
import { createEmotionCache } from '../utils/create-emotion-cache';
import '../i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppSettingsConsumer } from '../components/app-settings-consumer';

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Onderhoudsconfigurator</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <SettingsProvider>
            <QueryClientProvider
              client={
                new QueryClient({
                  defaultOptions: {
                    queries: {
                      refetchOnWindowFocus: false,
                      retry: false,
                    },
                  },
                })
              }
            >
              <AppSettingsConsumer Component={Component} pageProps={pageProps} />
            </QueryClientProvider>
          </SettingsProvider>
        </AuthProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default App;
