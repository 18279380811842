import axios from 'axios';
import { includeRequestHeaders } from './include-request-headers';
import { User } from '../types/organisation';

const apiClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/users`,
});

includeRequestHeaders(apiClient);

const userApi = {
  add: async (data): Promise<boolean> => {
    const response = await apiClient.post(``, data);

    return response.status === 202;
  },
  list: async (params?): Promise<User[]> => {
    const response = await apiClient.get(``, { params: params });

    return response.data;
  },
  patch: async (userId: string, data): Promise<boolean> => {
    const response = await apiClient.patch(`/${userId}`, data, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    });

    return response.status === 202;
  },
  get: async (userId: string): Promise<User> => {
    const response = await apiClient.get(`/${userId}`);

    return response.data;
  },
  getMe: async (): Promise<User> => {
    const response = await apiClient.get(`/me`);

    return response.data;
  },
  disable: async (userId: string, updateUserStatus: (id: string, active: boolean) => void): Promise<boolean> => {
    const response = await apiClient.post(`/${userId}/disable`, {});

    updateUserStatus(response.data.id, response.data.active);

    return response.status === 202;
  },
  enable: async (userId: string, updateUserStatus: (id: string, active: boolean) => void): Promise<boolean> => {
    const response = await apiClient.post(`/${userId}/enable`, {});

    updateUserStatus(response.data.id, response.data.active);

    return response.status === 202;
  },
};

export default userApi;
