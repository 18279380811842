import React, { FC, useState } from 'react';
import { SettingsConsumer } from '../contexts/settings-context';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '../theme';
import { RTL as Rtl } from './rtl';
import CssBaseline from '@mui/material/CssBaseline';
import { Toaster } from 'react-hot-toast';
import { ToTopButton } from './to-top';
import { AuthConsumer } from '../contexts/auth0-context';
import { SplashScreen } from './splash-screen';
import { NextPage } from 'next';
import { User } from '../types/organisation';
import userApi from '../client/user-client';
import { useAuth } from '../hooks/use-auth';
import { useQuery } from 'react-query';

interface AppSettingsConsumerProps {
  Component: NextPage;
  pageProps: any;
}

export const AppSettingsConsumer: FC<AppSettingsConsumerProps> = props => {
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout ?? (page => page);
  const user = useAuth();
  const [themeUser, setThemeUser] = useState<User | undefined>();

  useQuery(
    ['user'],
    async () => {
      const userData = await userApi.getMe();

      setThemeUser(userData);
    },
    { enabled: user.isAuthenticated },
  );

  return (
    <SettingsConsumer>
      {({ settings }) => (
        <ThemeProvider
          theme={createTheme(
            {
              direction: settings.direction,
              responsiveFontSizes: settings.responsiveFontSizes,
              mode: settings.theme,
            },
            themeUser,
          )}
        >
          <Rtl
            // @ts-ignore
            direction={settings.direction}
          >
            <CssBaseline />
            <Toaster position="top-center" />
            <ToTopButton />
            <AuthConsumer>
              {
                // @ts-ignore
                auth => (!auth.isInitialized ? <SplashScreen /> : getLayout(<Component {...pageProps} />))
              }
            </AuthConsumer>
          </Rtl>
        </ThemeProvider>
      )}
    </SettingsConsumer>
  );
};
