import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from '../config';
import type { User } from '../types/user';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';

export let auth0Client: Auth0Client | null = null;

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends State {
  platform: 'Auth0';
  accessToken: (options?: any) => Promise<void>;
  loginWithPopup: (options?: any) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Auth0',
  accessToken: () => Promise.resolve(),
  loginWithPopup: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
          cacheLocation: 'localstorage',
        });

        const isAuthenticated = await auth0Client.isAuthenticated();
        const user = await auth0Client.getUser();
        if (isAuthenticated && user) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: {
                // @ts-ignore
                id: user?.sub,
                // @ts-ignore
                avatar: user.picture,
                // @ts-ignore
                email: user.email,
                // @ts-ignore
                name: user.name,
                plan: 'Premium',
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const accessToken = async (options?: any) => {
    await auth0Client?.getTokenSilently(options);
  };

  const loginWithPopup = async (options): Promise<void> => {
    await auth0Client?.loginWithPopup(options);
    const isAuthenticated = await auth0Client?.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client?.getUser();

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            // @ts-ignore
            id: user.sub,
            // @ts-ignore
            avatar: user.picture,
            // @ts-ignore
            email: user.email,
            // @ts-ignore
            name: user.name,
            plan: 'Premium',
          },
        },
      });
    }
  };

  const logout = (): void => {
    // @ts-ignore
    auth0Client.logout({
      returnTo: window.location.origin + '/'
    });
    dispatch({
      type: 'LOGOUT',
    });
  };

  useEffect(() => {
    const checkIdToken = async () => {
      try {
        const idTokenClaims = await auth0Client?.getIdTokenClaims({});
        const token = idTokenClaims?.__raw;

        if (token) {
          const decodedToken = jwtDecode(token);

          if (decodedToken?.exp) {
            if (decodedToken.exp * 1000 < Date.now()) {
              logout();
            }
          }
        }
      } catch (error) {
        toast.error('Error getting idToken');
      }
    };

    checkIdToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        accessToken,
        loginWithPopup,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
