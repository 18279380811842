import chroma from 'chroma-js';

export const calculateColors = (baseColor?) => {
  if (!baseColor) {
    return { main: '#01315A', light: '#2172f5', dark: '#01315A', contrastText: '#FFFFFF' };
  }

  const mainColor = chroma(baseColor).hex();
  const lightColor = chroma(baseColor).brighten(1.5).hex();
  const darkColor = chroma(baseColor).darken(0.5).hex();
  const contrastText = chroma.contrast(baseColor, 'black') > 3.5 ? 'black' : 'white';

  return { main: mainColor, light: lightColor, dark: darkColor, contrastText };
};
