import type { FC } from 'react';
import { Box, ThemeOptions } from '@mui/material';
import { keyframes, useTheme } from '@emotion/react';

const bounce1 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 1px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const bounce3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 3px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const SplashScreen: FC = () => {
  const theme: ThemeOptions = useTheme();

  return (
    <Box
      sx={{
        alignItems: 'center',
        // @ts-ignore
        backgroundColor: theme.palette?.primary.main,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 2000,
      }}
    >
      <Box
        component={'img'}
        alt={'logo'}
        src={'/static/SMART_RealEstate_Beeldmerk_Diapos.png'}
        sx={{
          height: '100%',
          maxHeight: 100,
          width: '100%',
          maxWidth: 500,
          '& path:nth-child(1)': {
            animation: `${bounce1} 1s ease-in-out infinite`,
          },
          '& path:nth-child(3)': {
            animation: `${bounce3} 1s ease-in-out infinite`,
          },
        }}
      />
    </Box>
  );
};
