import type { Direction, TableCellProps, Theme } from '@mui/material';
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { baseThemeOptions } from './base-theme-options';
import { defaultThemeOptions } from './default-theme-options';
import { User } from '../types/organisation';

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface Palette {
    neutral?: Neutral;
  }

  // eslint-disable-next-line no-unused-vars
  interface PaletteOptions {
    neutral?: Neutral;
  }
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: 'light';
}

export const createTheme = (config: ThemeConfig, user?: User): Theme => {
  const defaultTheme = defaultThemeOptions(user);

  let theme = createMuiTheme(baseThemeOptions, defaultTheme, {
    direction: config.direction,
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export const numberContentStyle: TableCellProps = {
  align: 'right',
};
