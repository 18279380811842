import React from 'react';
import { Fab, Tooltip, Fade } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useScrollTrigger from '@mui/material/useScrollTrigger';

export const ToTopButton = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  return (
    <Tooltip title="Scroll to Top">
      <Fade in={trigger}>
        <Fab
          onClick={scrollTop}
          size="small"
          sx={{
            bottom: 0,
            margin: theme => theme.spacing(4),
            position: 'fixed',
            right: 0,
            zIndex: 1900,
          }}
        >
          <ExpandLessIcon fontSize="small" />
        </Fab>
      </Fade>
    </Tooltip>
  );
};
